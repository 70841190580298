<template>
    <Multiselect
        v-model="value"
        @select="select"
        @deselect="select"
        @clear="clear"
        :options="options"
        :searchable="searchable"
        :required="required"
        :disabled="disabled"
        :valueProp="option_value"
        :label="option_label"
        :track-by="option_label"
        :placeholder="placeholder"
        noResultsText="項目なし"
        class="form-select-search multiselect-blue"
    >
    </Multiselect>
    <pre class="language-json"><code>{{ value ? value[this.option_label] : ''  }}</code></pre>
</template>

<script>
import Multiselect from '@vueform/multiselect';

// npm
// https://www.npmjs.com/package/@vueform/multiselect#events
export default {
    name: 'FormSelectObjectSearch',
    components: {
        Multiselect,
    },
    data() {
        return {
            value: null,
        }
    },
    props: {
        modelValue: {
            type: Object,
            default: null
        },
        options: {
            type: Array
        },
        option_value: {
            type: String,
            default: 'value'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '----',
        },
        searchable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        'update:modelValue',
        'selected',
    ],
    mounted() {
        this.value = this.modelValue ? this.modelValue[this.option_value] : null;
    },
    watch: {
        modelValue: function (newValue) {
            // console.log(newValue);
            if (newValue) {
                this.value = newValue[this.option_value];
            }
        },
    },
    methods: {
        select(value) {
            this.$emit(
                'update:modelValue',
                this.options.find(option => option[this.option_value] === value)
            );
            this.$emit('selected', this.options.find(option => option[this.option_value] === value));
        },
        deselect() {
            this.$emit('update:modelValue', null);
            this.$emit('selected', null);
        },
        clear() {
            this.$emit('update:modelValue', null);
            this.$emit('selected', null);
        },
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css" />
<style>
.form-select-search .multiselect-multiple-label,
.form-select-search .multiselect-placeholder,
.form-select-search .multiselect-single-label {
    line-height: 1 !important;
}
</style>
<style scoped>
.multiselect-blue {
    --ms-line-height: 0.74;
    --ms-font-size: 0.9rem;
    --ms-option-bg-selected: #33AACC;
    --ms-option-bg-selected-pointed: #33AACC;
    --ms-py: 0.47rem;
    --ms-option-font-size: 0.9rem;
}
</style>